import { Router } from 'preact-router'

import Header from './header'

import Home from '../routes/home'
import ItemPage from '../routes/itempage'

export default function App() {
    return (
        <div id="app">
            <Header />
            <Router>
                <Home default />
                <ItemPage path="/item/:no" />
            </Router>
        </div>
    )
}